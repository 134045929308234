// gatsby-browser.js

import "./src/style/reset.scss";
import "./src/style/_index.scss";
import "./src/style/about.scss";
import "./src/style/acquisition_bullets.scss";
import "./src/style/animations.scss";
import "./src/style/background_section.scss";
import "./src/style/bootstrap.min.css";
import "./src/style/bulleted_list.scss";
import "./src/style/call_to_action.scss";
import "./src/style/career_opportunities.scss";
import "./src/style/careers.scss";
import "./src/style/chapter_hero.scss";
import "./src/style/chapters.scss";
import "./src/style/clients.scss";
import "./src/style/color.scss";
import "./src/style/coverage.scss";
import "./src/style/destinations.scss";
import "./src/style/float_card.scss";
import "./src/style/fonts.scss";
import "./src/style/footer.scss";
import "./src/style/header.scss";
import "./src/style/headline.scss";
import "./src/style/hero_section.scss";
import "./src/style/layout.scss";
import "./src/style/main.scss";
import "./src/style/mixins.scss";
import "./src/style/pitch_block.scss";
import "./src/style/practice_acquisition.scss";
import "./src/style/selling_points.scss";
import "./src/style/selling_practice.scss";
import "./src/style/social_icons.scss";
import "./src/style/sunset_opportunities.scss";
import "./src/style/svg.scss";
import "font-awesome/css/font-awesome.min.css";

export const onClientEntry = () => {
  console.log("Gatsby is now running in the browser! 🚀");
};

if ('scrollRestoration' in window.history) {
  window.history.scrollRestoration = 'manual';
}

export const onRouteUpdate = ({ location }) => {
  if (location.hash) {
    setTimeout(() => {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }, 300); // Small delay ensures Gatsby fully renders the page
  }
};



